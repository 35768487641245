<template>
  <v-list-group
    :value="value"
    :prepend-icon="prependIcon"
    no-action
  >
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title v-html="title" />
      </v-list-item-content>
    </template>
    <template v-for="item in items">
      <v-list-item
        v-if="!item.isDeleted"
        :key="`drawer-${item.id}`"
        :to="`/${itemPath}/${item.id}`"
        exact
      >
        <v-list-item-content>
          <v-list-item-title v-html="item.name" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list-group>
</template>

<script>
export default {
  name: 'DrawerEOSItemGroup',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    itemPath: {
      type: String,
      required: true
    },
    prependIcon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>
