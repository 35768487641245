import store from '@/store'
import { analytics } from '@/helpers/firebase'

export const logEvent = (eventName, eventParams = {}) => {
  if (!store.getters.isTeamworkUser) {
    analytics().logEvent(eventName, eventParams)
  }
}

export default {
  install (Vue) {
    Vue.prototype.$analytics = logEvent
  }
}
