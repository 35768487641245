import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import { auth } from '@/helpers/firebase'
import { logEvent } from '@/plugins/analytics'
import DataManager from '@/helpers/dataManager'

const PageHome = () => import(/* webpackChunkName: "home" */ '@/pages/PageHome.vue')
const PageGuideWeeklyMeeting = () => import(/* webpackChunkName: "home" */ '@/pages/PageGuideWeeklyMeeting.vue')
const PageGuideScorecard = () => import(/* webpackChunkName: "home" */ '@/pages/PageGuideScorecard.vue')
const PageGuideOrganisationalChart = () => import(/* webpackChunkName: "home" */ '@/pages/PageGuideOrganisationalChart.vue')
const PageGuideVisionAndPlan = () => import(/* webpackChunkName: "home" */ '@/pages/PageGuideVisionAndPlan.vue')
const PageGuideKeyPriorities = () => import(/* webpackChunkName: "home" */ '@/pages/PageGuideKeyPriorities.vue')
const PageTemplates = () => import(/* webpackChunkName: "home" */ '@/pages/PageTemplates.vue')
const PageFAQs = () => import(/* webpackChunkName: "home" */ '@/pages/PageFAQs.vue')
const PageVTO = () => import(/* webpackChunkName: "vision" */ '@/pages/PageVTO.vue')
const PageLevel10 = () => import(/* webpackChunkName: "meeting" */ '@/pages/PageLevel10.vue')
const PageAccountabilityChart = () => import(/* webpackChunkName: "chart" */ '@/pages/PageAccountabilityChart.vue')
const PageScorecard = () => import(/* webpackChunkName: "scorecard" */ '@/pages/PageScorecard.vue')
const PageKeyPriorities = () => import(/* webpackChunkName: "keyprioritiestool" */ '@/pages/PageKeyPriorities.vue')
const PageTermsOfService = () => import(/* webpackChunkName: "home" */ '@/pages/PageTermsOfService.vue')
const PagePrivacyPolicy = () => import(/* webpackChunkName: "home" */ '@/pages/PagePrivacyPolicy.vue')
const PageAboutUs = () => import(/* webpackChunkName: "home" */ '@/pages/PageAboutUs.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'home',
    component: PageHome,
    meta: {
      coloredBg: true
    }
  },
  {
    path: '/weeklymeeting',
    name: 'weeklymeeting',
    component: PageGuideWeeklyMeeting,
    meta: {
      coloredBg: true
    }
  },
  {
    path: '/organisationalchart',
    name: 'organisationalchart',
    component: PageGuideOrganisationalChart,
    meta: {
      coloredBg: true
    }
  },
  {
    path: '/kpiscorecard',
    name: 'kpiscorecard',
    component: PageGuideScorecard,
    meta: {
      coloredBg: true
    }
  },
  {
    path: '/visionandplan',
    name: 'visionandplan',
    component: PageGuideVisionAndPlan,
    meta: {
      coloredBg: true
    }
  },
  {
    path: '/key-priorities',
    name: 'key-priorities',
    component: PageGuideKeyPriorities,
    meta: {
      coloredBg: true
    }
  },
  {
    path: '/templates',
    name: 'templates',
    component: PageTemplates,
    meta: {
      coloredBg: true
    }
  },
  {
    path: '/weeklymeetings/:level10Id',
    name: 'weeklymeetings',
    component: PageLevel10
  },
  {
    path: '/level10s/:level10Id',
    name: 'level10',
    redirect: { name: 'weeklymeetings' }
  },
  {
    path: '/organisationalcharts/:chartId',
    name: 'organisational-charts',
    component: PageAccountabilityChart
  },
  {
    path: '/accountabilitycharts/:chartId',
    name: 'accountability-charts',
    redirect: { name: 'organisational-charts' }
  },
  {
    path: '/kpiscorecards/:scorecardId',
    name: 'kpiscorecards',
    component: PageScorecard
  },
  {
    path: '/scorecards/:scorecardId',
    name: 'scorecard',
    redirect: { name: 'kpiscorecards' }
  },
  {
    path: '/visionandplans/:vtoId',
    name: 'visionandplans',
    component: PageVTO
  },
  {
    path: '/vtos/:vtoId',
    name: 'vto',
    redirect: { name: 'visionandplans' }
  },
  {
    path: '/keypriorities/:keyPrioritiesId',
    name: 'keypriorities',
    component: PageKeyPriorities
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: PageAboutUs,
    meta: {
      coloredBg: true
    }
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: PagePrivacyPolicy,
    meta: {
      coloredBg: true
    }
  },
  {
    path: '/termsofservice',
    name: 'termsofservice',
    component: PageTermsOfService,
    meta: {
      coloredBg: true
    }
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: PageFAQs,
    meta: {
      coloredBg: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, _from, _savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, _from, next) => {
  const code = to.query.code || ''
  const state = to.query.state || ''

  if (!code) return next()

  if (state.startsWith('le')) { // clicked "Sync with TW" btn from dropdown on L10 page
    next({
      name: 'weeklymeetings',
      params: { level10Id: state, code }
    })

    return logEvent('meeting_synced_with_teamwork', { id: state })
  } else if (state.startsWith('ke')) { // clicked "Sync with TW" btn from dropdown on Rocks page
    next({
      name: 'keypriorities',
      params: { keyPrioritiesId: state, code }
    })

    return logEvent('kptool_synced_with_teamwork', { id: state })
  }

  // login with teamwork
  try {
    const { data } = await store.dispatch('getTeamworkAuthToken', code)

    // add security by passing token and verifying req on firebase function
    const { user } = await auth().signInAnonymously()
    await store.dispatch('setLoggedInUser', user)
    const token = await auth().currentUser.getIdToken(true)

    // generate custom token for login
    const res = await store.dispatch('getCustomToken', { token, email: data.user.email })
    await auth().signInWithCustomToken(res.data.token)

    // update name as per teamwork profile
    const currentUser = auth().currentUser
    currentUser.updateProfile({
      displayName: `${data.user.firstName} ${data.user.lastName}`,
      photoURL: ''
    })

    // save sync params for reuse
    const dataManager = new DataManager()
    dataManager.updateLoggedInUserData({
      twAccessToken: data.access_token,
      twAPIEndPoint: data.installation ? data.installation.apiEndPoint : '',
      twURL: data.installation ? data.installation.url : '',
      twSiteName: data.installation ? data.installation.name : ''
    })

    next({ path: `${state}?useAccSyncParams=true` })
  } catch (error) {
    store.dispatch('setMessage', { type: 'error', message: error.message })
    next({ path: state })
  }
})

router.afterEach((to) => {
  logEvent('page_visit', { name: to.name })
})

export default router
