import Vue from 'vue'
import VueSentry from '@/plugins/sentry'
import vuetify from '@/plugins/vuetify'
import VueMeta from '@/plugins/vueMeta'
import FirebaseAnalytics from '@/plugins/analytics'
import App from '@/App'
import store from '@/store'
import router from '@/router'
import SharedComponents from '@/plugins/sharedComponents'
import GlobalHelpers from '@/plugins/globalHelpers'

import '@/styles/global.scss'

Vue.config.ignoredElements = ['teamwork-login-button']

Vue.use(VueSentry)
Vue.use(VueMeta)
Vue.use(FirebaseAnalytics)
Vue.use(SharedComponents)
Vue.use(GlobalHelpers)

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')
