// Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat

const numberOnly = (value) => Number(value.replace(/[^0-9.-]+/g, ''))

export default function (amount, opts = {}) {
  const value = parseFloat(amount)
  if (isNaN(value)) return amount

  const options = Object.assign({}, opts)
  options.currencyDisplay = options.currencyDisplay || 'narrowSymbol'
  options.minimumFractionDigits = options.minimumFractionDigits || 0
  options.maximumFractionDigits = options.maximumFractionDigits || 2
  options.prefix = !options.currency && options.prefix ? options.prefix : ''
  options.suffix = !options.currency && options.suffix ? options.suffix : ''
  options.currency = options.currency || 'USD'

  const formatter = new Intl.NumberFormat(options.locale || undefined, {
    ...options,
    style: 'currency' // if the style is "currency", the currency property must be provided
  })
  const formattedValue = formatter.format(parseFloat(value))
  // return only format currency without symbol
  if (options.noSymbol) return numberOnly(formattedValue)

  return (options.prefix || options.suffix) ? `${options.prefix} ${numberOnly(formattedValue)} ${options.suffix}`
    : formattedValue
}
