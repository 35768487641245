import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/analytics'
import 'firebase/storage'

const isProd = process.env.NODE_ENV !== 'development'

const config = isProd ? {
  apiKey: 'AIzaSyDVqIQkihQnV2GIH2Y0HGsw0SHjk8G2jgQ',
  authDomain: 'instantagencytools.com',
  databaseURL: 'https://eos-tools.firebaseio.com',
  projectId: 'eos-tools',
  storageBucket: 'eos-tools.appspot.com',
  messagingSenderId: '414304215170',
  appId: '1:414304215170:web:9b85f8a2039cb6455d149b',
  measurementId: 'G-PQWTN5E4XW'
} : {
  apiKey: 'AIzaSyAkRh83VBTpUDy8c9dmFPiRzYqRcrroFww',
  authDomain: 'eos-tools-dev.firebaseapp.com',
  databaseURL: 'https://eos-tools-dev.firebaseio.com',
  projectId: 'eos-tools-dev',
  storageBucket: 'eos-tools-dev.appspot.com',
  messagingSenderId: '716120237575',
  appId: '1:716120237575:web:cf06fa0f4bf7a4b0f46ca2'
}

firebase.initializeApp(config)

const analytics = firebase.analytics
const auth = firebase.auth
const db = firebase.firestore()
const functions = firebase.functions()
const storage = firebase.storage()

if (!isProd && process.env.VUE_APP_USE_EMULATORS === 'true') {
  auth().useEmulator('http://localhost:9099')
  db.useEmulator('localhost', 8080)
  functions.useEmulator('localhost', 5001)
  // firebase.setLogLevel('debug')
  analytics().setAnalyticsCollectionEnabled(false) // disable analytics
}

export default firebase
export { analytics, auth, db, functions, storage }
