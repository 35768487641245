import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

// if DSN is null, sentry will ignore the error
// We are using this to avoid error reporting in 'development' mode
// Ref: https://github.com/getsentry/sentry-java/issues/574
//
const sentryDSN = process.env.VUE_APP_SENTRY_DSN

Sentry.init({
  dsn: sentryDSN,
  integrations: [
    new Integrations.BrowserTracing(),
    new VueIntegration({
      Vue,
      tracing: true
    })
  ],
  ignoreErrors: [
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
    'ResizeObserver loop',
    'A network error', // lost connection
    'deadline-exceeded',
    'Illegal invocation',
    'FIRESTORE (8.4.2) INTERNAL ASSERTION FAILED',
    'Avoided redundant navigation to current location',
    'Failed to get document because the client is offline.',
    'Missing or insufficient permissions', // firebase 403
    // Expected from route guards
    // Ref: https://router.vuejs.org/guide/advanced/navigation-failures.html#navigationfailuretype
    /^Navigation cancelled/,
    /^Loading chunk/
  ]
})

export const logError = (error, context = {}) => Sentry.withScope(scope => {
  scope.setExtras(context.extra)
  Sentry.captureException(error)
})

export default {
  install (Vue) {
    Vue.prototype.$logError = logError
  }
}
