<template>
  <svg aria-hidden="true" class="eos-icon eos-icon__target" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.69 25.68">
    <g>
      <g>
        <path d="M11.49,25.68a11.49,11.49,0,1,1,0-23,11.72,11.72,0,0,1,3.17.44.5.5,0,0,1,.3.25.46.46,0,0,1,0,.38.51.51,0,0,1-.49.37l-.14,0a10.45,10.45,0,1,0,7.17,7.18.51.51,0,0,1,.35-.63l.14,0a.5.5,0,0,1,.49.37A11.66,11.66,0,0,1,23,14.2,11.49,11.49,0,0,1,11.49,25.68m0-7.31a4.17,4.17,0,0,1,0-8.34h.37a.51.51,0,0,1,.46.56.5.5,0,0,1-.5.46h-.33a3.15,3.15,0,1,0,3.15,3.15,2.64,2.64,0,0,0,0-.28.5.5,0,0,1,.12-.37.52.52,0,0,1,.35-.18h0a.51.51,0,0,1,.51.46c0,.12,0,.25,0,.37a4.17,4.17,0,0,1-4.16,4.17m.23-3.89a.55.55,0,0,1-.36-.15.51.51,0,0,1,0-.72l5-5V5.11a1.72,1.72,0,0,1,.5-1.22L20.42.33A1.11,1.11,0,0,1,21.21,0a1.07,1.07,0,0,1,.43.09,1.11,1.11,0,0,1,.69,1V3.35h2.23a1.11,1.11,0,0,1,1,.7,1.13,1.13,0,0,1-.24,1.22L21.8,8.83a1.75,1.75,0,0,1-1.23.5H17.08l-5,5a.51.51,0,0,1-.36.15M18.1,8.31h2.47a.69.69,0,0,0,.51-.21l3.73-3.73H22L18.1,8.31m-.52-3.7a.76.76,0,0,0-.21.5V7.59l3.94-3.94V.88L17.58,4.61"/>
        <path d="M11.32,22a7.83,7.83,0,0,1,0-15.66,7.69,7.69,0,0,1,2.89.55.51.51,0,0,1-.19,1l-.19,0a6.9,6.9,0,0,0-2.51-.47,6.81,6.81,0,1,0,6.81,6.81,6.9,6.9,0,0,0-.47-2.51.5.5,0,0,1,.28-.66l.19,0a.5.5,0,0,1,.47.33,7.61,7.61,0,0,1,.55,2.88A7.84,7.84,0,0,1,11.32,22"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconTarget'
}
</script>
