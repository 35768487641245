<template>
  <svg aria-hidden="true" class="eos-icon eos-icon__rocks" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.16 28.17">
    <g>
      <g>
        <path d="M10.22,14,14.14,18,18,14.14,14,10.22ZM8.65,14,14,8.65l5.51,5.51-5.36,5.36ZM13.93,1.7a.57.57,0,0,0-.43.18L1.88,13.5a.59.59,0,0,0,0,.85L13.81,26.29a.63.63,0,0,0,.43.17.59.59,0,0,0,.42-.17L26.29,14.66a.61.61,0,0,0,0-.85L14.35,1.88a.56.56,0,0,0-.42-.18m.34,26.47a.45.45,0,0,1-.29-.12L.12,14.18a.39.39,0,0,1,0-.56L13.62.12a.39.39,0,0,1,.56,0L28.05,14a.44.44,0,0,1,.11.29.43.43,0,0,1-.11.28l-13.5,13.5a.44.44,0,0,1-.28.12"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconRocks'
}
</script>
