import { generateRandomNumber } from '@/helpers/globalHelpers'

const state = {
  sessionUID: (
    new Date().getTime() + generateRandomNumber()
  ).toString(16), // a unique id used for syncing, doesn't have to be perfect
  loggedInUser: undefined, // undefined means we're currently looking it up, null means we checked but user not logged in
  loggedInUserData: {
    linkedVTOs: [],
    linkedCharts: [],
    linkedLevel10s: [],
    linkedScorecards: [],
    linkedKeyPriorities: [],
    twSites: []
  },
  isFirebaseReady: false,
  messageText: '',
  messageType: '',
  showMessage: false,
  isSideNavOpen: false
}

export default state
