import axios from 'axios'
import { functions } from '@/helpers/firebase'

const actions = {
  setLoggedInUser ({ commit }, user) {
    commit('SET_LOGGED_IN_USER', user)
  },
  setLoggedInUserData ({ commit }, userData) {
    commit('SET_LOGGED_IN_USER_DATA', userData)
  },
  setFirebaseReady ({ commit }, status = true) {
    commit('SET_FIREBASE_READY_STATUS', status)
  },
  setMessage ({ commit }, messageInfo) {
    commit('SET_MESSAGE', messageInfo)
    commit('SET_MESSAGE_STATUS', true)

    setTimeout(() => commit('SET_MESSAGE_STATUS', false), messageInfo.timeout || 3000)
  },
  hideMessage ({ commit }) {
    commit('SET_MESSAGE_STATUS', false)
  },
  toggleSideNav ({ commit }) {
    commit('TOGGLE_SIDE_NAV')
  },
  getAccountDetails (_context) {
    return axios.get('/account.json')
  },
  getProjects (_context) {
    return axios.get('/projects.json')
  },
  getProjectById (_context, projectId) {
    return axios.get(`/projects/${projectId}.json`)
  },
  getTasklists (_context, projectId) {
    return axios.get(`/projects/${projectId}/tasklists.json`)
  },
  getTasklistById (_context, tasklistId) {
    return axios.get(`/tasklists/${tasklistId}.json`)
  },
  getNotebooks (_context, projectId) {
    return axios.get(`/projects/${projectId}/notebooks.json`)
  },
  getUserById (_context, userId) {
    return axios.get(`/projects/api/v2/people/${userId}.json?fullprofile=0&page=1&pageSize=1`)
  },
  getUserByEmailId (_context, emailAddress) {
    return axios.get(`/projects/api/v2/people.json?returnLetters=false&getCounts=false&includeClockIn=false&page=1&pageSize=1&searchTerm=${emailAddress}&type=all&returnTeams=false&getProjectRoles=false&sort=firstName&sortOrder=desc&viewDuplicateUserEmails=false`)
  },
  getTask (_context, taskId) {
    return axios.get(`/tasks/${taskId}.json`)
  },
  quickAddTask (_context, task) {
    return axios.post(`/tasklists/${task.tasklistId}/quickadd.json`, task)
  },
  updateTask (_context, task) {
    return axios.put(`/tasks/${task.id}.json`, { 'todo-item': task })
  },
  updateTaskStatus (_context, { taskId, status }) {
    return axios.put(`/tasks/${taskId}/${status}.json`)
  },
  deleteTodo (_context, taskId) {
    return axios.delete(`/tasks/${taskId}.json`)
  },
  getNotebookById (_context, notebookId) {
    return axios.get(`/notebooks/${notebookId}.json`)
  },
  sendMeetingSummaryMail (_context, meetingId) {
    return functions.httpsCallable('sendMeetingSummary')({ meetingId })
  },
  exportToNotebook (_context, meetingId) {
    return functions.httpsCallable('exportMeetingToNotebook')({ meetingId })
  },
  sendInvite (_context, { invitee, inviter, resourceName, resourcePath }) {
    return functions.httpsCallable('invite')({ invitee, inviter, resourceName, resourcePath })
  },
  toggleKeypriorityForItems (_context, { keyPriority, type, linkedItems }) {
    return functions.httpsCallable('toggleKeypriority')({ keyPriority, type, linkedItems })
  },
  syncKeypriority (_context, { keyPriority, groupId }) {
    return functions.httpsCallable('syncKeypriority')({ keyPriority, groupId })
  },
  syncKeyprioritiesFromTeamwork (_context, keyPrioritiesId) {
    return functions.httpsCallable('syncKeyprioritiesFromTeamwork')({ keyPrioritiesId })
  },
  syncTask (_context, { keyPriority, docId, parentTaskId }) {
    return functions.httpsCallable('syncTask')({ keyPriority, docId, parentTaskId })
  },
  deleteTask (_context, { keyPriority, taskId }) {
    return functions.httpsCallable('deleteTask')({ keyPriority, taskId })
  },
  getUserByEmail (_context, email) {
    return functions.httpsCallable('getUserByEmail')({ email })
  },
  getCustomToken (_context, { token, email }) {
    return functions.httpsCallable('getCustomToken')({ token, email })
  },
  getTeamworkAuthToken (_context, code) {
    return functions.httpsCallable('getTeamworkAuthToken')({ code })
  },
  configAxios (_context, { token, isAPIKey, apiEndPoint }) {
    return new Promise((resolve, reject) => {
      // change endopint based on installation
      if (apiEndPoint && axios.defaults.baseURL !== apiEndPoint) { axios.defaults.baseURL = apiEndPoint }

      // oAuth2.0
      if (!isAPIKey) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
        return resolve()
      }

      // if user has API Key
      axios.defaults.auth = {
        username: token,
        password: ''
      }

      return resolve()
    })
  }
}

export default actions
