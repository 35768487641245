import { getUnixTimestamp } from '@/helpers/globalHelpers'

export default function (unixTimestamp) {
  const currentTime = getUnixTimestamp()
  const diffInSecs = currentTime - unixTimestamp
  let diff = 1
  let suffix = ''

  if (diffInSecs < 59) { // < 1 min
    diff = diffInSecs + 1
    suffix = 'sec'
  } else if (diffInSecs < (60 * 60)) { // < 1 hr
    diff = Math.floor(diffInSecs / 60)
    suffix = 'min'
  } else if (diffInSecs < (60 * 60 * 24)) { // < 1 day
    diff = Math.floor(diffInSecs / (60 * 60))
    suffix = 'hour'
  } else if (diffInSecs < (60 * 60 * 24 * 30)) { // < 1 month
    diff = Math.floor(diffInSecs / (60 * 60 * 24))
    suffix = 'day'
  } else if (diffInSecs < (60 * 60 * 24 * 30 * 365)) { // < 1 year
    diff = Math.floor(diffInSecs / (60 * 60 * 24 * 30))
    suffix = 'month'
  } else {
    diff = Math.floor(diffInSecs / (60 * 60 * 24 * 365))
    suffix = 'year'
  }

  if (diff > 1) suffix += 's'
  return `${diff} ${suffix} ago`
}
