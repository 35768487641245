<template>
  <svg aria-hidden="true" class="eos-icon eos-icon__tw" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="284.86763677 270.29 505.13475078 539.52121448">
    <g>
      <g>
        <path d="m402.55 579c0 31.48-.51 63 .16 94.43.64 29.88 18.18 41.26 46 31 33-12.21 60.24 9.74 56 45.22-1.82 15.26-8.89 26.69-23.19 33.9-39.26 19.8-80.21 22.71-121.67 10.45-38.57-11.41-53.52-42.41-58.9-79.34a207.08 207.08 0 0 1 -2.06-29.55q-.21-175.36 0-350.75c0-39 32.2-64.07 67.33-53.09 22.05 6.9 35.46 26.09 36.28 52.69.4 13 .79 26.13-.15 39.1-.72 10 3.05 11.85 12.11 11.52 18.86-.7 37.77-.41 56.65-.15 27.17.37 45 18.24 45.07 44.75.06 27.32-16.82 43.91-45.13 44.13-18.43.15-36.89.56-55.29-.18-10-.4-13.81 1.65-13.49 12.83.85 31.04.27 62.04.28 93.04z" fill="#1b1c39"/><path d="m690.12 800.9c-50.06-.06-91-41.4-90.43-91.37.53-50.29 41.22-90.11 91.87-89.91 49.42.2 89.75 41 89.7 90.67a90.65 90.65 0 0 1 -91.14 90.61z" fill="#fc21ae"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconTeamwork'
}
</script>
