<template>
  <svg aria-hidden="true" class="eos-icon eos-icon__core-values" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.45 19.91">
    <g>
      <g>
        <path d="M11.74,19.91a2.31,2.31,0,0,1-1.63-.67L1.89,11a6.45,6.45,0,0,1,0-9.12,6.43,6.43,0,0,1,9.11,0l.74.74.74-.74a6.41,6.41,0,0,1,11,4.57,6.54,6.54,0,0,1-1.91,4.6l-8.17,8.18a2.33,2.33,0,0,1-1.63.67M6.44.94a5.51,5.51,0,0,0-3.88,9.4l8.22,8.23a1.35,1.35,0,0,0,1.92,0l8.17-8.17a5.61,5.61,0,0,0,1.64-3.94,5.48,5.48,0,0,0-9.36-3.91l-2.49,2.5a.48.48,0,0,1-.34.14A.47.47,0,0,1,10,5.05a.48.48,0,0,1-.14-.34A.47.47,0,0,1,10,4.38L11.07,3.3l-.74-.75A5.5,5.5,0,0,0,6.44.94"/>
        <path d="M20.15,6.94a.47.47,0,0,1-.47-.45,3.41,3.41,0,0,0-1-2.17,3.45,3.45,0,0,0-1.47-.87.46.46,0,0,1-.33-.57.46.46,0,0,1,.45-.35l.13,0a4.31,4.31,0,0,1,1.89,1.1,4.38,4.38,0,0,1,1.25,2.79.48.48,0,0,1-.44.49h0"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconCoreValues'
}
</script>
