// Global helpers
// Use as this.$helpers.functionName() in component or
// Vue.helpers.functionName() anywhere in your application
//

import helpers from '@/helpers/globalHelpers'

export default {
  install (Vue) {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
  }
}
