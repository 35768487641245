// Shared components
import AppLoader from '@/components/shared/misc/AppLoader'
import AppAvatar from '@/components/shared/misc/AppAvatar'
import AppDialogConfirmDelete from '@/components/shared/dialogs/AppDialogConfirmDelete'
import AppDataTable from '@/components/shared/datatable/AppDataTable'

// Shared filters
import currency from '@/filters/currency'
import groupBy from '@/filters/groupBy'
import shortName from '@/filters/shortName'
import nameByEmail from '@/filters/nameByEmail'
import nlToBr from '@/filters/nlToBr'
import nlToList from '@/filters/nlToList'
import humanize from '@/filters/humanize'

export default {
  install (Vue) {
    // Register global components
    Vue.component('AppLoader', AppLoader)
    Vue.component('AppAvatar', AppAvatar)
    Vue.component('AppDialogConfirmDelete', AppDialogConfirmDelete)
    Vue.component('AppDataTable', AppDataTable)

    // Register global filter
    Vue.filter('groupBy', groupBy)
    Vue.filter('currency', currency)
    Vue.filter('shortName', shortName)
    Vue.filter('nameByEmail', nameByEmail)
    Vue.filter('nlToBr', nlToBr)
    Vue.filter('nlToList', nlToList)
    Vue.filter('humanize', humanize)
  }
}
