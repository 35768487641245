import linkify from './linkify'

export default function (text) {
  if (!text) return ''

  text = ('' + text)
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
  text = linkify(text)

  return text.replace(/\n/g, '<br />')
}
