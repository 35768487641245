const getters = {
  sessionUID: state => state.sessionUID,
  loggedInUser: state => state.loggedInUser,
  loggedInUserData: state => {
    const {
      linkedVTOs,
      linkedLevel10s,
      linkedCharts,
      linkedScorecards,
      linkedKeyPriorities,
      twAccessToken,
      twAPIEndPoint,
      twURL,
      twSiteName,
      twSites
    } = state.loggedInUserData

    return {
      linkedVTOs: linkedVTOs.filter(item => !item.isDeleted),
      linkedLevel10s: linkedLevel10s.filter(item => !item.isDeleted),
      linkedCharts: linkedCharts.filter(item => !item.isDeleted),
      linkedScorecards: linkedScorecards.filter(item => !item.isDeleted),
      linkedKeyPriorities: linkedKeyPriorities.filter(item => !item.isDeleted),
      twAccessToken: twAccessToken || '',
      twAPIEndPoint: twAPIEndPoint || '',
      twURL: twURL || '',
      twSiteName: twSiteName || '',
      twSites: twSites || []
    }
  },
  allLoggedInUserData: state => state.loggedInUserData,
  trashedItems: state => {
    const {
      linkedVTOs,
      linkedLevel10s,
      linkedCharts,
      linkedScorecards,
      linkedKeyPriorities
    } = state.loggedInUserData

    return {
      linkedVTOs: linkedVTOs.filter(item => !!item.movedToTrash),
      linkedLevel10s: linkedLevel10s.filter(item => !!item.movedToTrash),
      linkedCharts: linkedCharts.filter(item => !!item.movedToTrash),
      linkedScorecards: linkedScorecards.filter(item => !!item.movedToTrash),
      linkedKeyPriorities: linkedKeyPriorities.filter(item => !!item.movedToTrash)
    }
  },
  syncedTWSites: (_state, getters) => {
    const loggedInUserData = getters.loggedInUserData
    const sites = loggedInUserData.twSites.slice() || []

    if (!loggedInUserData.twAccessToken || !loggedInUserData.twAPIEndPoint || !loggedInUserData.twURL) {
      return sites.reverse() // last synced should be first
    }

    // add latest synced
    sites.push({
      twAccessToken: loggedInUserData.twAccessToken,
      twAPIEndPoint: loggedInUserData.twAPIEndPoint,
      twURL: loggedInUserData.twURL,
      twSiteName: loggedInUserData.twSiteName
    })
    return sites.reverse()
  },
  isFirebaseReady: state => state.isFirebaseReady,
  showMessage: state => state.showMessage,
  messageType: state => state.messageType,
  messageText: state => state.messageText,
  isSideNavOpen: state => state.isSideNavOpen,
  isTeamworkUser: state => state.loggedInUser && state.loggedInUser.email && state.loggedInUser.email.indexOf('@teamwork.com') > -1 // state.loggedInUser && ADMINS.includes(state.loggedInUser.email)
}

export default getters
