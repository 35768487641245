<template>
  <svg aria-hidden="true" class="eos-icon eos-icon__core-focus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.69 20.33">
    <g>
      <g>
        <path d="M27.51,12.48a15.83,15.83,0,0,1-27.34,0L0,12.18l.17-.3A15.86,15.86,0,0,1,21.85,6.19a15.91,15.91,0,0,1,5.66,5.69l.18.3-.18.3ZM7,6.92,6.88,7a14.55,14.55,0,0,0-5.4,5l-.09.14.09.14a14.55,14.55,0,0,0,5.4,5.05l.12.06.3-.35L7.23,17a8.08,8.08,0,0,1,0-9.6l.07-.1L7,6.92Zm6.84-1.7a7,7,0,1,0,7,7,7,7,0,0,0-7-7ZM26.21,12A14.58,14.58,0,0,0,20.8,7l-.11-.07-.31.36.08.1a8.12,8.12,0,0,1,0,9.6l-.08.1.31.35.11-.06a14.58,14.58,0,0,0,5.41-5.05l.09-.14L26.21,12ZM13.84,15.36A3.18,3.18,0,0,1,13.59,9a2.74,2.74,0,0,0-.11.76,2.78,2.78,0,0,0,2.79,2.77,2.73,2.73,0,0,0,.76-.1,3.19,3.19,0,0,1-3.19,2.92ZM26.77,5.61a.57.57,0,0,1-.8,0,18.41,18.41,0,0,0-2.68-1.91,18.72,18.72,0,0,0-18.9,0A19.37,19.37,0,0,0,1.7,5.67a.55.55,0,0,1-.37.14h0a.53.53,0,0,1-.4-.18l-.05,0a.6.6,0,0,1-.16-.42.55.55,0,0,1,.2-.39,20.68,20.68,0,0,1,2.86-2,20,20,0,0,1,20.12,0,20.21,20.21,0,0,1,2.85,2,.59.59,0,0,1,0,.85Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconCoreFocus'
}
</script>
