import store from '@/store'
import { auth, db, functions, storage } from '@/helpers/firebase'

import { logError } from '@/plugins/sentry'
import { dateToYYYYMMDD } from '@/filters/dateToYYYYMMDD'
import { dateToLocalTZ } from '@/filters/dateToLocalTZ'
import { getUnixTimestamp } from '@/helpers/globalHelpers'
import MEETING_STATUSES from '@/enums/meetingStatuses'

export default class DataManager {
  constructor () {
    this.subscriptionsToCancel = []
  }

  hashString (str) {
    let hash = 0
    let i
    let chr
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i)
      hash = (hash << 5) - hash + chr
      hash |= 0 // Convert to 32bit integer
    }
    return hash.toString(36)
  }

  getUniqueId () {
    let id = ''
    if (store.getters.loggedInUser.uid) { id += this.hashString(store.getters.loggedInUser.uid) }
    id += Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(36)
    return id
  }

  getAppInfo () {
    return db.collection('app').doc('info').get()
  }

  async loginAnonymously () {
    try {
      const { user } = await auth().signInAnonymously()
      return store.dispatch('setLoggedInUser', user)
    } catch (error) {
      console.error('Error logging anonymously', error.message)
      throw error
    }
  }

  async createObject (type, inOpts = {}) {
    if (['vtos', 'charts', 'scorecards', 'level10s', 'keypriorities', 'kpMapping'].indexOf(type) === -1) throw new Error(`Invalid type ${type}`)

    try {
      // if user is not logged in, log in anonymously before creating an object
      if (!store.getters.loggedInUser) await this.loginAnonymously()

      // create an Id for this object
      const objectId = inOpts.id || type.substring(0, 2) + this.getUniqueId()

      // nested under meta and data properties
      const options = Object.assign({
        meta: {
          name: inOpts.name || this.getNewObjectName(type),
          lastUpdate: {
            sessionUID: store.getters.sessionUID,
            uid: store.getters.loggedInUser.uid,
            timestamp: new Date().getTime()
          },
          visibility: 'public'
        },
        data: this.getNewObjectData(type, inOpts.template)
      }, inOpts)

      if (['level10s', 'keypriorities'].includes(type) &&
        !store.getters.loggedInUser.isAnonymous &&
        store.getters.loggedInUserData.twAccessToken) {
        const syncOptions = options.meta.twSyncOptions || {}
        options.meta.twSyncOptions = {
          ...syncOptions,
          accessToken: syncOptions.accessToken || store.getters.loggedInUserData.twAccessToken,
          apiEndPoint: syncOptions.apiEndPoint || store.getters.loggedInUserData.twAPIEndPoint,
          domain: syncOptions.domain || store.getters.loggedInUserData.twURL,
          siteName: syncOptions.siteName || store.getters.loggedInUserData.twSiteName,
          syncedBy: store.getters.loggedInUser.email,
          syncedOn: getUnixTimestamp()
        }
      }

      // save data on firebase
      await db.collection(type).doc(objectId).set({
        meta: options.meta,
        data: options.data
      })
      return { objectId }
    } catch (error) {
      logError(error, { extra: JSON.stringify(inOpts) })
      throw error
    }
  }

  // Load data from firebase and watch for future changes
  validateObject (type, objectId, doc) {
    let object = null
    if (doc && doc.data) object = doc.data()

    const isInvalidObject = typeof object !== 'object' || object === null
    const hasInvalidMeta = typeof object.meta !== 'object' || object.meta === null
    const hasInvalidData = typeof object.data !== 'object' || object.data === null

    if (isInvalidObject || hasInvalidMeta || hasInvalidData) throw new Error('Invalid data found')

    // update name in sidebar, insert it if it's not there already
    // and we are not looking at an archived item
    if (!object.meta.seguence?.nextId) this.updateObjectNameInList(type, objectId, object.meta.name)
    return object
  }

  async syncObject (type, objectId, callback, inOptions = {}) {
    const options = Object.assign({}, { sync: true }, inOptions)

    try {
      const doc = await this.loadObject(type, objectId, options)

      const unsubscribe = doc.onSnapshot(snapshot => {
        try {
          const object = type === 'users' ? snapshot : this.validateObject(type, objectId, snapshot)
          callback(null, object)
        } catch (error) {
          console.error('Error syncing object:', error)
          callback(error, null)
        }
      }, error => callback(error, null))
      this.subscriptionsToCancel.push(unsubscribe)
    } catch (error) {
      callback(error, null)
    }
  }

  async loadObject (type, objectId, inOptions = {}) {
    if (['vtos', 'charts', 'scorecards', 'level10s', 'keypriorities', 'users', 'kpMapping'].indexOf(type) === -1) throw new Error(`Invalid type: ${type}`)
    if (!objectId) throw new Error(`Invalid objectId: ${objectId}`)

    try {
      // if user is not logged in, log in anonymously before getting an object
      if (!store.getters.loggedInUser) await this.loginAnonymously()

      const options = Object.assign({}, { sync: false }, inOptions)
      const doc = db.collection(type).doc(objectId)

      if (options.sync) return doc

      const object = await doc.get()
      return ['users', 'kpMapping'].includes(type) ? object : this.validateObject(type, objectId, object)
    } catch (error) {
      console.error('Error getting object:', error)
      throw error
    }
  }

  async updateObject (type, objectId, params) {
    // firebase will reject the call if there is undefined values
    // work around is mentioned here - https://github.com/googleapis/nodejs-firestore/issues/1031#issuecomment-636308604
    // but it's better to set to null rather ignoring undefined values
    // below code converts undefined values to null
    params = JSON.parse(JSON.stringify(params, function (k, v) {
      return v === undefined ? null : v
    }))

    if (['vtos', 'charts', 'scorecards', 'level10s', 'keypriorities', 'kpMapping'].indexOf(type) === -1) throw new Error(`Invalid type: ${type}`)
    if (!objectId) throw new Error(`Invalid objectId: ${objectId}`)

    try {
      // if user is not logged in, log in anonymously before getting an object
      if (!store.getters.loggedInUser) await this.loginAnonymously()

      // Remember who updated last
      params['meta.lastUpdate'] = {
        sessionUID: store.getters.sessionUID,
        uid: store.getters.loggedInUser.uid,
        timestamp: new Date().getTime()
      }

      return db.collection(type).doc(objectId).update(params)
    } catch (error) {
      logError(error, {
        extra: { id: objectId, payload: JSON.stringify(params) }
      })
      throw error
    }
  }

  async saveObject (type, objectId, object) {
    if (['vtos', 'charts', 'scorecards', 'level10s', 'keypriorities', 'kpMapping'].indexOf(type) === -1) throw new Error(`Invalid type: ${type}`)
    if (!objectId) throw new Error(`Invalid objectId: ${objectId}`)

    if (typeof object !== 'object' || typeof object.data !== 'object' || typeof object.meta !== 'object') {
      return new Error('Invalid object', object)
    }

    try {
      // if user is not logged in, log in anonymously before getting an object
      if (!store.getters.loggedInUser) await this.loginAnonymously()

      // Remember who updated last
      object.meta.lastUpdate = {
        sessionUID: store.getters.sessionUID,
        uid: store.getters.loggedInUser.uid,
        timestamp: new Date().getTime()
      }

      return db.collection(type).doc(objectId).set(object)
    } catch (error) {
      logError(error, {
        extra: { id: objectId, payload: JSON.stringify(object) }
      })
      throw error
    }
  }

  // Changed to a soft delete! We mark the object as deleted now.
  async deleteObject (type, objectId) {
    try {
      // Load the object first
      const object = await this.loadObject(type, objectId)
      // Delete the object
      object.meta.isDeleted = true // mark it as deleted in the meta data
      await this.saveObject(type, objectId, object)
      // Remove from sidebar/homepage list
      await this.updateUserReferenceToObject(type, objectId, 'trash')

      // move to trash for each attendees
      functions.httpsCallable('moveToTrash')({
        objectId,
        attendees: (object.data.attendees || object.data.users).map(u => u.email)
      })

      if (object.meta.seguence?.previousId) {
        return this.deleteObject(type, object.meta.seguence.previousId)
      }

      return Promise.resolve(object)
    } catch (error) {
      console.error('Error deleting document:', error)
      throw error
    }
  }

  async restoreObjects (type, objectIds) {
    if (['vtos', 'charts', 'scorecards', 'level10s', 'keypriorities'].indexOf(type) === -1) throw new Error(`Invalid type: ${type}`)
    if (objectIds.length === 0) throw new Error('\'objectIds\' can\'t be empty')

    const promises = objectIds.map(objectId => this.updateUserReferenceToObject(type, objectId, 'restore'))
    await Promise.all(promises)

    functions.httpsCallable('restore')({ type, objectIds })
  }

  hardDelete (type, objectId) {
    return db.collection(type).doc(objectId).delete()
  }

  destroy () {
    this.cancelSubscriptions() // PREVENT MEMORY LEAKS
  }

  cancelSubscriptions () {
    this.subscriptionsToCancel.forEach((unsubscribe) => unsubscribe())
  }

  updateUserReferenceToObject (type, objectId, operation = 'delete') {
    if (['vtos', 'charts', 'scorecards', 'level10s', 'keypriorities'].indexOf(type) === -1) throw new Error(`Invalid type: ${type}`)
    if (!objectId) throw new Error(`Invalid objectId: ${objectId}`)

    const linkedListName = this.getLinkedListNameByType(type)
    const linkedItems = this.getSidebarListByType(type)
    if (linkedItems === null) throw new Error('Item not found')

    const objectIndex = linkedItems.findIndex(item => item.id === objectId)
    if (objectIndex === -1) return Promise.resolve()

    if (['delete', 'trash'].includes(operation)) linkedItems[objectIndex].isDeleted = true // soft delete => hide item from list
    if (operation === 'trash') linkedItems[objectIndex].movedToTrash = true
    if (operation === 'restore') {
      delete linkedItems[objectIndex].isDeleted
      delete linkedItems[objectIndex].movedToTrash
    }

    const data = {}
    data[linkedListName] = linkedItems
    return this.updateLoggedInUserData(data)
  }

  async updateLoggedInUserData (data) {
    const currData = store.getters.allLoggedInUserData
    const userData = { ...currData, ...data }

    try {
      store.dispatch('setLoggedInUserData', userData) // update dashboard
      await db.collection('users').doc(store.getters.loggedInUser.uid).update(data)
      return Promise.resolve(userData)
    } catch (error) {
      // we are creating users/{docId} only when required to save dashboard data
      if (error.code === 'not-found') {
        await db.collection('users').doc(store.getters.loggedInUser.uid).set(userData)
        return Promise.resolve(userData)
      }
      logError(error, {
        extra: { currData: JSON.stringify(currData), newData: JSON.stringify(data) }
      })
      throw error
    }
  }

  getLinkedListNameByType (type) {
    let listName = null
    switch (type) {
      case 'vtos':
        listName = 'linkedVTOs'
        break
      case 'charts':
        listName = 'linkedCharts'
        break
      case 'scorecards':
        listName = 'linkedScorecards'
        break
      case 'level10s':
        listName = 'linkedLevel10s'
        break
      case 'keypriorities':
        listName = 'linkedKeyPriorities'
        break
      default:
        console.error('unknown type', type)
    }
    return listName
  }

  getSidebarListByType (type) {
    const linkedListName = this.getLinkedListNameByType(type)
    return linkedListName === null ? null : store.getters.allLoggedInUserData[linkedListName]
  }

  getSidebarRefInListById (type, objectId) {
    const linkedItems = this.getSidebarListByType(type)
    return linkedItems.find((x) => x.id === objectId)
  }

  getNewObjectName (type) {
    const d = new Date()
    const dtf = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    })
    const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d)
    const dateString = ` ${da}-${mo}-${ye}`

    let rootName = ''
    switch (type) {
      case 'vtos':
        rootName = 'My Vision and Plan'
        break
      case 'charts':
        rootName = `New Chart ${dateString}`
        break
      case 'scorecards':
        rootName = `New Scorecard ${dateString}`
        break
      case 'level10s':
        rootName = 'My Team' // We do not want the date on these!
        break
      case 'keypriorities':
        rootName = 'New Priorities'
        break
    }

    const linkedListName = this.getLinkedListNameByType(type)
    const linkedItems = store.getters.loggedInUserData[linkedListName]

    // If the name is already taken, this appends #2 or #3 etc.
    let loopNo = 1
    let nameIsOK = false
    let proposedName = ''
    do {
      proposedName = rootName
      if (loopNo > 1) proposedName += ` #${loopNo}`
      if (linkedItems.findIndex((x) => x.name === proposedName) === -1) {
        nameIsOK = true
      } else {
        loopNo++
      }
    } while (!nameIsOK)

    return proposedName
  }

  getNewObjectData (type, template) {
    const data = {}
    const today = new Date()
    const quarter = Math.floor((today.getMonth() / 3)) + 1

    switch (type) {
      case 'vtos':
        data.version = 1
        data.coreValues = ''
        data.coreFocus = {
          purpose: '',
          niche: ''
        }
        data.tenYearTarget = ''
        data.marketingStrategy = {
          targetMarket: '',
          uniques: '',
          provenProcess: '',
          guarantee: ''
        }
        data.threeYearPicture = {
          futureDate: this.getDateInYYYYMMDD(new Date().setFullYear(today.getFullYear() + 3)),
          extraFields: [],
          measurables: '',
          whatDoesItLookLike: ''
        }
        data.oneYearPlan = {
          futureDate: this.getDateInYYYYMMDD(new Date(new Date().setFullYear(today.getFullYear() + 1))),
          extraFields: [],
          measurables: '',
          goals: []
        }
        data.rocks = {
          futureDate: this.getDateInYYYYMMDD(new Date(today.getFullYear(), quarter * 3, 1)),
          extraFields: [],
          measurables: '',
          quaterlyRocks: []
        }
        data.issues = []
        data.previousVersions = []
        data.users = []
        break
      case 'charts':
        data.topLevelSeat = {
          id: new Date().valueOf(),
          name: '',
          responsibilities: '',
          subs: [{
            id: (new Date().valueOf() + 1),
            name: '',
            responsibilities: ''
          },
          {
            id: (new Date().valueOf() + 2),
            name: '',
            responsibilities: ''
          }]
        }
        data.tags = []
        data.users = []
        break
      case 'scorecards':
        switch (template) {
          case 'marketing':
            data.metrics = [
              {
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                dataType: 'Percentage',
                goalType: '>=',
                goal: 5,
                title: 'Traffic to Lead',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                }
              },
              {
                title: 'Lead to Quote',
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                goalType: '<=',
                owner: '',
                goal: 20,
                dataType: 'Number',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                }
              },
              {
                goal: 50,
                dataType: 'Number',
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                title: 'Sales Ratio',
                goalType: '>=',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                }
              },
              {
                dataType: 'Currency',
                goal: 250,
                title: 'Cost per acquisition',
                owner: '',
                goalType: '<=',
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                }
              },
              {
                title: 'Social Media Engagement',
                goalType: '>=',
                dataType: 'Number',
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                goal: 20,
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                }
              },
              {
                goal: 3,
                title: 'Keyword Ranking',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                goalType: '<=',
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                dataType: 'Number'
              },
              {
                title: 'Adwords conversion',
                goalType: '>=',
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                dataType: 'Number',
                goal: 200,
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                }
              },
              {
                title: 'Customer Lifetime Value',
                dataType: 'Currency',
                goal: 7000,
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                goalType: '>=',
                owner: ''
              }
            ]
            break
          case 'design':
            data.metrics = [
              {
                goal: 2,
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                goalType: '<=',
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                dataType: 'Number',
                title: 'Manager response time (days)'
              },
              {
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                goalType: '<=',
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                goal: 5,
                dataType: 'Percentage',
                title: 'Requirements ambiguity'
              },
              {
                dataType: 'Number',
                goal: 2,
                goalType: '<=',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                title: 'Designer response time (days)'
              },
              {
                goal: 5,
                dataType: 'Number',
                goalType: '<=',
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                title: 'Time to first draft (days)'
              },
              {
                goal: 90,
                dataType: 'Percentage',
                title: 'Alignment with requirements',
                goalType: '>=',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  }
                ]
              },
              {
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                goal: 8,
                dataType: 'Number',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                goalType: '<=',
                title: 'Rework time (hours)'
              },
              {
                goalType: '>=',
                goal: 3,
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                dataType: 'Percentage',
                title: 'Time budget variance'
              },
              {
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                goal: 95,
                title: 'Client satisfaction',
                dataType: 'Percentage',
                goalType: '>=',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                }
              }
            ]
            break
          case 'sales':
            data.metrics = [
              {
                dataType: 'Number',
                goalType: '<=',
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                title: 'Time to first contact (hours)',
                goal: 8
              },
              {
                goalType: '<=',
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                title: 'Customer Acquisition Cost',
                goal: 250,
                dataType: 'Currency'
              },
              {
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                dataType: 'Number',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                goalType: '<=',
                goal: 30,
                title: 'Time to close (days)'
              },
              {
                title: 'Average deal size',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                dataType: 'Currency',
                goal: 5000,
                goalType: '>='
              },
              {
                dataType: 'Percentage',
                goalType: '>=',
                goal: 40,
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                title: 'Lead to qualified lead rate'
              },
              {
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                goalType: '>=',
                dataType: 'Percentage',
                title: 'Sales accepted leads = opportunities',
                goal: 85,
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  }
                ]
              },
              {
                dataType: 'Percentage',
                goalType: '>=',
                goal: 50,
                title: 'Opportunity to Close rate',
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                }
              }
            ]
            break
          case 'project-management':
            data.metrics = [
              {
                goalType: '>=',
                goal: 95,
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                title: 'Unambiguity of the requirements',
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                dataType: 'Percentage'
              },
              {
                goalType: '<',
                title: 'Financial budget variance',
                dataType: 'Percentage',
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                goal: 2,
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                }
              },
              {
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                goal: 2,
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: ''
                  }
                ],
                dataType: 'Number',
                title: 'Time estimated vs. actual',
                goalType: '<='
              },
              {
                title: 'Schedule variance',
                goalType: '<=',
                dataType: 'Number',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                goal: 2
              },
              {
                goalType: '>=',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                title: 'Project updates provided',
                goal: 2,
                dataType: 'Number'
              },
              {
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                title: 'Re-work hours',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                dataType: 'Number',
                goalType: '<=',
                goal: 5
              },
              {
                dataType: 'Number',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                goal: 3,
                title: '# Risks without mitigation plan',
                goalType: '<=',
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  }
                ]
              }
            ]
            break
          case 'software':
            data.metrics = [
              {
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                title: 'Uptime',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                dataType: 'Percentage',
                goalType: '>=',
                goal: 99.99
              },
              {
                title: 'Interruptions',
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                dataType: 'Number',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                goalType: '<=',
                goal: 2
              },
              {
                dataType: 'Number',
                goalType: '<=',
                goal: 10,
                title: 'Reported issues',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ]
              },
              {
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                dataType: 'Number',
                goal: 90,
                title: 'API average response time (ms)',
                goalType: '<=',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                }
              },
              {
                goalType: '<=',
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ],
                goal: 0.03,
                dataType: 'Number',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                title: '% of requests timeouts'
              },
              {
                goal: 10,
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                scores: [
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  }
                ],
                dataType: 'Number',
                goalType: '<=',
                title: 'Open pull requests'
              },
              {
                goal: 5,
                dataType: 'Number',
                title: '# of escaped major severity bugs',
                currencyOptions: {
                  locale: null,
                  currency: 'USD',
                  prefix: '',
                  suffix: ''
                },
                goalType: '<=',
                scores: [
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    reason: '',
                    score: null
                  },
                  {
                    score: null,
                    reason: ''
                  },
                  {
                    score: null,
                    reason: ''
                  }
                ]
              }
            ]
            break
          default:
            data.metrics = [{
              title: 'Sample metric',
              dataType: 'Number',
              goal: 50,
              goalType: '>=',
              scores: [{
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              }]
            },
            {
              title: 'Percentage awesome',
              dataType: 'Percentage',
              goal: 100,
              goalType: '>=',
              scores: [{
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              },
              {
                score: null,
                reason: ''
              }]
            }]
        }
        data.monthlyMetrics = []
        data.scoreDates = []
        data.hiddenColumns = {
          weekly: [],
          monthly: []
        }
        data.initialView = ''
        data.users = []
        break
      case 'level10s':
        data.elapsedTimeInSecs = 0
        data.meetingStatus = MEETING_STATUSES.PLANNED
        data.meetingIsRunning = false
        data.meetingDateYYYYMMDD = this.getDateInYYYYMMDD()
        data.scribe = ''

        data.scorecard = this.getNewObjectData('scorecards', template)
        delete data.scorecard.users

        data.attendees = []
        if (store.getters.loggedInUser !== null && !store.getters.loggedInUser.isAnonymous) {
          data.attendees.push({
            displayName: store.getters.loggedInUser.displayName,
            photoURL: store.getters.loggedInUser.photoURL,
            email: store.getters.loggedInUser.email,
            isPresent: true
          })
        }
        data.plan = {
          imageURL: '',
          notes: '',
          hiddenSections: [],
          carriedOver: true,
          hasFacilitator: true
        }
        data.segue = { notes: [] }
        data.IDS = { issues: [] }
        data.rocks = { rocks: [] }
        data.todos = { todos: [] }
        data.headlines = { headlines: [] }
        data.conclude = {
          extraTodos: [],
          meetingRatings: [],
          messageToShare: ''
        }
        break
      case 'keypriorities':
        data.users = []
        data.priorities = []
        break
      case 'kpMapping':
        data.level10s = []
        data.vtos = []
        data.keypriorities = []
        break
      default:
        console.log('No initial data')
    }
    return data
  }

  updateObjectNameInList (type, objectId, name) {
    if (name === '' || !type || !objectId) return // defensive

    const linkedListName = this.getLinkedListNameByType(type)
    if (linkedListName === null) return null
    const linkedItems = this.getSidebarListByType(type)

    // Get the level10/chart/scrorecard data from linked arrays if possible
    const data = {}
    data[linkedListName] = linkedItems
    const sidebarListRef = this.getSidebarRefInListById(type, objectId)

    if (sidebarListRef && typeof sidebarListRef === 'object') {
      if (sidebarListRef.name === name && !sidebarListRef.isDeleted) return
      delete sidebarListRef.isDeleted
      sidebarListRef.name = name
    } else {
      linkedItems.push({ id: objectId, name: name })
    }

    try {
      this.updateLoggedInUserData(data)
    } catch (error) {
      console.error('Error updating object name in list: ', error)
      throw error
    }
  }

  getDateInYYYYMMDD (meetingDateObj) {
    return dateToYYYYMMDD(meetingDateObj)
  }

  getDateInLocalTZ (date) {
    return dateToLocalTZ(date)
  }

  getStorageRef () {
    return storage.ref()
  }
}
