<template>
  <v-dialog
    v-model="showDialogLogin"
    persistent
    max-width="450px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="btnColor" :loading="showLoader" height="32px" class="text-body-2" x-large v-bind="attrs" v-on="on">
        {{ actionType }}
      </v-btn>
    </template>
    <v-form
      ref="form"
      v-model="isValid"
      :lazy-validation="true"
      @submit.prevent="submit"
    >
      <v-card>
        <v-card-title class="headline px-4">
          <template v-if="isRegistering">Register</template>
          <template v-else-if="isResettingPassword">Reset Password</template>
          <template v-else-if="isChangingPassword">Change Password</template>
          <template v-else>Sign In</template>
        </v-card-title>
        <v-divider />

        <v-card-text class="px-4 pt-3 pb-1">
          <template v-if="!isResettingPassword && !isChangingPassword">
            <div class="d-flex">
              <teamwork-login-button
                :redirectURI="redirectURI"
                :clientID="clientID"
                :state="currentRoute"
                size="medium"
                borders="default"
                color="white"
                class="mx-auto"
              />
            </div>
            <v-btn
              class="d-flex mx-auto pa-2 mt-5 auth-modal__btn"
              width="220px"
              color="rgb(218, 221, 235)"
              outlined
              @click="$emit('login-with-google')"
            >
              <v-avatar size="20px" class="auth-modal__btn__logo">
                <v-img src="@/assets/images/google.png" alt="Login with Google" />
              </v-avatar>
              <span class="ml-n2">{{ actionType }} with Google</span>
            </v-btn>
            <v-btn
              class="d-flex mx-auto pa-2 my-5 auth-modal__btn"
              width="220px"
              color="rgb(218, 221, 235)"
              outlined
              @click="$emit('login-with-microsoft')"
            >
              <v-avatar size="20px" class="auth-modal__btn__logo">
                <v-img src="@/assets/images/microsoft.png" alt="Login with Microsoft" />
              </v-avatar>
              <span class="pl-2">{{ actionType }} with Microsoft</span>
            </v-btn>
            <div class="d-flex">
              <v-divider class="mt-3" />
              <p class="text-center mx-2 mb-2">OR</p>
              <v-divider class="mt-3" />
            </div>
          </template>

          <v-text-field
            v-if="isRegistering"
            v-model="name"
            :rules="nameRules"
            label="Name"
            class="mt-2"
            outlined
            required
          />

          <v-text-field
            v-if="!isChangingPassword"
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            class="mt-2"
            outlined
            required
          />

          <div v-if="!isResettingPassword" class="position-relative">
            <v-text-field
              v-model="password"
              :rules="paswordRules"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              class="mt-2"
              outlined
              required
              @click:append="showPassword = !showPassword"
            />
            <p
              v-show="!isRegistering"
              class="primary--text position-absolute mt-n3 ml-3 cursor-pointer"
              @click="isResettingPassword = true"
            >Forgot password?</p>
          </div>

          <v-text-field
            v-if="isRegistering || isChangingPassword"
            v-model="confrimPassword"
            :rules="confrimPaswordRules"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            label="Confirm Password"
            class="mt-2"
            outlined
            required
            @click:append="showConfirmPassword = !showConfirmPassword"
          />
        </v-card-text>
        <v-divider :class="{ 'mt-3': !isRegistering && !isResettingPassword }" />

        <v-card-actions class="px-4 py-3">
          <span v-if="isRegistering" class="text-body-2 text--secondary">
            Already have an account?
            <span class="primary--text cursor-pointer" @click="isRegistering = false">Sign in</span>
          </span>
          <span v-else-if="isResettingPassword || isChangingPassword" class="text-body-2 text--secondary">
            Remember password?
            <span class="primary--text cursor-pointer" @click="isResettingPassword = false">Sign in</span>
          </span>
          <span v-else class="text-body-2 text--secondary">
            Don't have an account?
            <span class="primary--text cursor-pointer" @click="isRegistering = true">Register</span>
          </span>
          <v-spacer />
          <v-btn text @click="closeDialog">Cancel</v-btn>
          <v-btn :loading="showLoader" :disabled="showLoader" type="submit" color="success" @click="submit">
            <template v-if="isRegistering">Register</template>
            <template v-else-if="isResettingPassword">Reset</template>
            <template v-else-if="isChangingPassword">Update</template>
            <template v-else>Sign in</template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import '@teamwork/login-button'

export default {
  name: 'AppSignIn',
  props: {
    btnColor: {
      type: String,
      default: ''
    },
    showLoader: {
      type: Boolean,
      default: false
    },
    needRegistration: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showDialogLogin: false,
      isRegistering: false,
      isResettingPassword: false,
      isChangingPassword: false,
      isValid: true,
      name: '',
      nameRules: [
        v => !!v || 'Name is required'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      password: '',
      paswordRules: [
        v => !!v || 'Password is required',
        v => (v || '').length >= 6 || 'Password must be atleast 6 characters long',
        v => ((this.confrimPassword || '').length === 0 || v === this.confrimPassword) || 'Password is not matching with confirm password'
      ],
      confrimPassword: '',
      confrimPaswordRules: [
        v => !!v || 'Confirm password is required'
      ],
      showPassword: false,
      showConfirmPassword: false
    }
  },
  computed: {
    actionType () {
      return this.isRegistering ? 'Register' : 'Sign in'
    },
    redirectURI () {
      return process.env.VUE_APP_TEAMWORK_REDIRECT_URI
    },
    clientID () {
      return process.env.VUE_APP_TEAMWORK_CLIENT_ID
    },
    currentRoute () {
      return this.$route.path
    }
  },
  watch: {
    needRegistration (value) {
      this.isRegistering = value
    }
  },
  methods: {
    submit () {
      const isValid = this.$refs.form.validate()
      if (!isValid) return

      if (this.isResettingPassword) return this.$emit('reset-password', this.email)
      if (this.isChangingPassword) return this.$emit('update-password', this.password)

      const evtName = this.isRegistering ? 'signup-with-email-pass' : 'login-with-email-pass'
      this.$emit(evtName, {
        name: this.name,
        email: this.email,
        password: this.password
      })
    },
    openDialog () {
      this.isRegistering = true
      this.isResettingPassword = false
      this.isChangingPassword = false
      this.showDialogLogin = true
    },
    closeDialog () {
      this.showDialogLogin = false
      this.$refs.form.reset()

      setTimeout(() => {
        this.isRegistering = this.needRegistration
      }, 50)
    },
    openResetPasswordDialog () {
      this.isRegistering = false
      this.isChangingPassword = false
      this.isResettingPassword = true
      this.showDialogLogin = true
    },
    openChangePasswordDialog () {
      this.isRegistering = false
      this.isResettingPassword = false
      this.isChangingPassword = true
      this.showDialogLogin = true
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-modal__btn {
  &__logo {
    position: absolute;
    left: 0px;
  }

  &.v-btn {
    box-shadow: rgb(0, 0, 0, 0.1) 0px 1px 4px;

    &:hover {
      background-color: #fff;
      border: 1px solid rgb(197, 202, 218);
      box-shadow: rgb(0, 0, 0, 0.1) 0px 2px 8px;
    }

    span {
      text-transform: initial;
      color: #0B0E1F;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      letter-spacing: 0px;
    }
  }
}
</style>
