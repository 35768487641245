<template>
  <v-navigation-drawer
    :value="isSideNavOpen"
    app
    clipped
    @change="$emit('toggle')"
  >
    <v-list dense class="py-0">
      <DrawerEOSItemLink
        item-path="/"
        prepend-icon="mdi-home"
        title="Home"
      />

      <DrawerEOSItemGroup
        v-if="loggedInUserData.linkedLevel10s.length > 0"
        :key="`wm-${loggedInUserData.linkedLevel10s.length}`"
        :value="$route.name === 'weeklymeetings'"
        :items="loggedInUserData.linkedLevel10s"
        title="Weekly Meetings"
        item-path="weeklymeetings"
        prepend-icon="mdi-calendar"
      />
      <DrawerEOSItemLink
        v-else
        item-path="/weeklymeeting"
        prepend-icon="mdi-calendar"
        title="Weekly Meeting"
      />

      <DrawerEOSItemGroup
        v-if="loggedInUserData.linkedKeyPriorities.length > 0"
        :key="`kp-${loggedInUserData.linkedKeyPriorities.length}`"
        :value="$route.name === 'keypriorities'"
        :items="loggedInUserData.linkedKeyPriorities"
        title="Key Priorities"
        item-path="keypriorities"
        prepend-icon="mdi-bullseye-arrow"
      />
      <DrawerEOSItemLink
        v-else
        item-path="/key-priorities"
        prepend-icon="mdi-bullseye-arrow"
        title="Key Priorities"
      />

      <DrawerEOSItemGroup
        v-if="loggedInUserData.linkedVTOs.length > 0"
        :key="`vnp-${loggedInUserData.linkedVTOs.length}`"
        :value="$route.name === 'visionandplans'"
        :items="loggedInUserData.linkedVTOs"
        title="Vision and Plans"
        item-path="visionandplans"
        prepend-icon="mdi-binoculars"
      />
      <DrawerEOSItemLink
        v-else
        item-path="/visionandplan"
        prepend-icon="mdi-binoculars"
        title="Vision and Plan"
      />

      <DrawerEOSItemGroup
        v-if="loggedInUserData.linkedCharts.length > 0"
        :key="`chart-${loggedInUserData.linkedCharts.length}`"
        :value="$route.name === 'organisational-charts'"
        :items="loggedInUserData.linkedCharts"
        title="Org. Charts"
        item-path="organisationalcharts"
        prepend-icon="mdi-family-tree"
      />
      <DrawerEOSItemLink
        v-else
        item-path="/organisationalchart"
        prepend-icon="mdi-family-tree"
        title="Organisational Chart"
      />

      <DrawerEOSItemGroup
        v-if="loggedInUserData.linkedScorecards.length > 0"
        :key="`sc-${loggedInUserData.linkedScorecards.length}`"
        :value="$route.name === 'kpiscorecards'"
        :items="loggedInUserData.linkedScorecards"
        title="Scorecards"
        item-path="kpiscorecards"
        prepend-icon="mdi-table-large"
      />
      <DrawerEOSItemLink
        v-else
        item-path="/kpiscorecard"
        prepend-icon="mdi-table-large"
        title="Scorecard"
      />

      <DrawerEOSItemLink
        item-path="/templates"
        prepend-icon="mdi-file-document-outline"
        title="Templates"
      />

      <v-list-item exact :to="{ name: 'faqs' }">
        <v-list-item-action>
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>FAQs</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import DrawerEOSItemLink from '@/components/drawer/DrawerEOSItemLink'
import DrawerEOSItemGroup from '@/components/drawer/DrawerEOSItemGroup'

export default {
  name: 'TheDrawer',
  components: {
    DrawerEOSItemLink,
    DrawerEOSItemGroup
  },
  computed: {
    ...mapGetters([
      'isSideNavOpen',
      'loggedInUser',
      'loggedInUserData'
    ])
  }
}
</script>
