export default function (text) {
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
  const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim

  return text
    .replace(urlRegex, function (url, b, c) {
      const newURL = (c === 'www.') ? `http://${url}` : url
      return `<a href="${newURL}" target="_blank">${newURL}</a>`
    })
    .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>')
}
