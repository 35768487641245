<template>
  <svg aria-hidden="true" class="eos-icon eos-icon__plan" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.72 22.66">
    <g>
      <g>
        <path d="M22.19,22.66H.53A.53.53,0,0,1,0,22.14V1.8c0-.27.21-.42.55-.42H4.14V.55A.55.55,0,0,1,4.69,0a.55.55,0,0,1,.55.55v.83H17.47V.55a.56.56,0,0,1,1.11,0v.83h3.59c.34,0,.55.15.55.42V22.13a.53.53,0,0,1-.53.53M1.06,21.61h20.6v-15H1.06v15M21.61,2.48h-3V3.3a.56.56,0,0,1-1.11,0V2.48H5.24V3.3a.54.54,0,0,1-.55.55.55.55,0,0,1-.55-.55V2.48h-3l0,3.09H21.67l-.06-3.09M14.2,16.22a2.08,2.08,0,1,1,2.09-2.07,2.08,2.08,0,0,1-2.09,2.07m0-3.23a1.16,1.16,0,1,0,0,2.31,1.16,1.16,0,0,0,0-2.31"/>
        <path d="M21.71,6.26H.9V1.76H21.71v4.5M1.1,2.48l0,3.09H21.67l-.06-3.09h-3V3.3a.56.56,0,0,1-1.11,0V2.48H5.24V3.3a.54.54,0,0,1-.55.55.55.55,0,0,1-.55-.55V2.48h-3"/>
        <path d="M14,19.22a.69.69,0,1,1,0-1.38.69.69,0,1,1,0,1.38m-5.1,0a.69.69,0,1,1,.69-.69.69.69,0,0,1-.69.69m-4.64,0a.69.69,0,1,1,0-1.38.69.69,0,1,1,0,1.38m14.37-4.16a.7.7,0,1,1,0-1.4.7.7,0,0,1,0,1.4m-9.73,0a.7.7,0,0,1,0-1.4.7.7,0,0,1,0,1.4m-4.64,0a.7.7,0,1,1,.69-.7.7.7,0,0,1-.69.7m14.37-4.17a.69.69,0,1,1,.7-.69.69.69,0,0,1-.7.69m-4.63,0a.69.69,0,1,1,.69-.69.69.69,0,0,1-.69.69m-5.1,0a.69.69,0,1,1,.69-.69.69.69,0,0,1-.69.69"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconPlan'
}
</script>
