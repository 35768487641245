<template>
  <v-app>
    <TheDrawer @toggle="toggleSideNav" />

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="secondary" dark flat dense class="eos-app__toolbar hidden-print">
      <v-app-bar-nav-icon @click="toggleSideNav" />
      <v-toolbar-title class="cursor-pointer pl-0 pl-md-2 eos-app__title" @click="goToHome">
        <span :class="isXSDevice ? 'text-body-1' : ''">Instant Agency Tools
        </span>
      </v-toolbar-title>

      <v-tabs v-model="selectedTab" class="d-none d-md-block ml-5 pl-5">
        <!-- selected if no match -->
        <v-tab href="/x" class="d-none">X</v-tab>
        <v-tab :to="{ path: '/' }">Home</v-tab>
        <v-menu v-if="isFirebaseReady && numberOfItems === 0" offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              height="48px"
              class="align-self-center grey--text"
              v-bind="attrs"
              v-on="on"
            >
              Tools
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>

          <v-list dense class="py-0">
            <v-list-item dense :to="{ path: '/weeklymeeting' }">Weekly Meeting</v-list-item>
            <v-list-item dense :to="{ path: '/key-priorities' }">Key Priorities</v-list-item>
            <v-list-item dense :to="{ path: '/organisationalchart' }">Organisational Chart</v-list-item>
            <v-list-item dense :to="{ path: '/kpiscorecard' }">Scorecard</v-list-item>
            <v-list-item dense :to="{ path: '/visionandplan' }">Vision & Plan</v-list-item>
            <v-list-item dense :to="{ path: '/templates' }">Templates</v-list-item>
          </v-list>
        </v-menu>
        <v-tab v-else :to="{ path: '/templates' }">Templates</v-tab>
        <v-tab :to="{ path: '/faqs' }">FAQs</v-tab>
      </v-tabs>

      <v-spacer />

      <v-btn v-if="isTeamworkUser" text small class="mt-1" @click="leaveFeedback">Leave Feedback</v-btn>
      <div class="d-flex align-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              :target="isTeamworkUser ? '_blank' : ''"
              :href="isTeamworkUser ? `https://digitalcrew.teamwork.com/#/projects/358852/tasks` : 'mailto:support@instantagencytools.com'"
            >
              <v-icon>{{ isTeamworkUser ? 'mdi-bug' : 'mdi-email-outline' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ isTeamworkUser ? 'Report a bug' : 'Contact Us' }}</span>
        </v-tooltip>

        <v-menu
          v-if="loggedInUser && !loggedInUser.isAnonymous"
          offset-y
        >
          <template v-slot:activator="{ on }" text="true">
            <v-btn class="text-capitalize px-0 px-sm-2 mr-n1" :text="true" tile height="48px" v-on="on">
              <span class="d-none d-md-block">{{ loggedInUser.displayName }}</span>
              <AppAvatar
                :src="loggedInUser.photoURL"
                :name="loggedInUser.displayName"
                name-class="white--text text-caption"
              />
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item @click="logout">
              <v-list-item-title>Log Off</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <AppSignIn
          v-else
          ref="signInModal"
          btn-color="secondary"
          :show-loader="showAuthModalLoader"
          @login-with-google="loginWithGoogle"
          @login-with-microsoft="loginWithMicrosoft"
          @login-with-email-pass="loginWithEmailPassword"
          @signup-with-email-pass="signupWithEmailPassword"
          @reset-password="resetPassword"
          @update-password="updatePassword"
        />
      </div>
    </v-app-bar>

    <v-main
      :class="{ 'eos-app__content eos-app__bg--gray': hasColoredBg }"
    >
      <v-layout
        v-if="!isFirebaseReady"
        column
        fill-height
        align-center
        justify-center
      >
        <AppLoader />
      </v-layout>
      <v-layout v-else column fill-height>
        <router-view
          :number-of-items="numberOfItems"
          :is-creating-vto="isCreatingVTO"
          :is-creating-scorecard="isCreatingScorecard"
          :is-creating-l10-meeting="isCreatingL10Meeting"
          :is-creating-accountability-chart="isCreatingAccountabilityChart"
          :is-creating-key-priorities="isCreatingKeyPriorities"
          :show-auth-loader="showAuthModalLoader"
          @create-l10="createLevel10Meeting"
          @create-vto="createVTO"
          @create-acc-chart="createAccountabilityChart"
          @create-scorecard="createScorecard"
          @create-key-priorities="createKeyPriorities"
          @login-with-google="loginWithGoogle"
          @login-with-microsoft="loginWithMicrosoft"
          @login-with-email-pass="loginWithEmailPassword"
          @signup-with-email-pass="signupWithEmailPassword"
          @reset-password="resetPassword"
          @update-password="updatePassword"
        />

        <v-footer
          v-if="hasFooter"
          class="py-3 px-10 flex-column flex-md-row mt-auto mx-auto eos-app__footer"
          color="transparent"
        >
          <v-col xs="12" md="5" class="text-center text-md-start">
            <span class="text--disabled">
              &copy;&nbsp;{{ new Date().getFullYear() }}
              <a href="https://teamwork.grsm.io/instantagencytools" target="_blank" class="text--disabled text-decoration-none">Teamwork.com</a>, Ltd. All rights reserved.
            </span>
          </v-col>

          <v-col class="text-center text-md-start">
            <router-link
              :to="{ name: 'aboutus' }"
              class="text-decoration-none text--disabled mr-5"
            >About Us</router-link>
            <router-link
              :to="{ name: 'termsofservice' }"
              class="text-decoration-none text--disabled mr-5"
            >Terms of service</router-link>
            <router-link
              :to="{ name: 'privacypolicy' }"
              class="text-decoration-none text--disabled mr-auto"
            >Privacy policy</router-link>
          </v-col>
        </v-footer>
      </v-layout>
    </v-main>

    <v-snackbar :value="showMessage" :color="messageType">
      <span v-html="messageText"></span>
      <v-btn text @click="hideMessage">Close</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

import { auth, db } from '@/helpers/firebase'
import DataManager from '@/helpers/dataManager'
import AppSignIn from '@/components/shared/auth/AppSignIn'
import TheDrawer from '@/components/drawer/TheDrawer'

export default {
  name: 'App',
  components: {
    AppSignIn,
    TheDrawer
  },
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s - InstantAgencyTools',
    meta: [
      {
        vmid: 'keywords',
        name: 'keywords',
        content: 'EOS Compatible, Entrepreneurial Operating System, Transparency, Alignment, Accountability, , Weekly Meeting, Weekly Meeting template, Solving issues, Vision, Mission vision and values of a company, Company values, Strategic planning, Create business plan, Traction accountability chart, Organization chart, Create Organizational chart, Create an Org chart, Business visionary, Business management roles and responsibilities, Quarterly Rocks, Business key priorities, Business health,  Smart objectives, Scorecard, Measurables'
      },
      {
        vmid: 'description',
        name: 'description',
        content: 'InstantAgencyTools is a collection of free tools to help your business run more productive meetings, get everyone behind your vision and track your business health. By having your business\'s meetings, scorecards, organisational chart and vision accessible in one place, you\'re ensuring transparency, alignment and accountability. InstantAgencyTools key priorities and to-dos sync with the Teamwork project management software suite.'
      }
    ]
  },
  data () {
    return {
      dataManager: new DataManager(),
      selectedTab: null,
      fetchingEOSItems: false,
      isCreatingVTO: false,
      isCreatingL10Meeting: false,
      isCreatingAccountabilityChart: false,
      isCreatingScorecard: false,
      isCreatingKeyPriorities: false,
      isLogging: false,
      isRegistering: false,
      sendingPasswordResetLink: false,
      updatingPassword: false
    }
  },
  computed: {
    ...mapGetters([
      'isFirebaseReady',
      'loggedInUser',
      'loggedInUserData',
      'showMessage',
      'messageType',
      'messageText',
      'isTeamworkUser'
    ]),
    hasColoredBg () {
      return this.$route.meta.coloredBg
    },
    hasFooter () {
      return this.hasColoredBg && (this.$route.name === 'home' ? this.numberOfItems === 0 : true)
    },
    isXSDevice () {
      return this.$vuetify.breakpoint.xsOnly
    },
    numberOfItems () {
      return this.loggedInUserData.linkedVTOs.length +
        this.loggedInUserData.linkedLevel10s.length +
        this.loggedInUserData.linkedCharts.length +
        this.loggedInUserData.linkedScorecards.length +
        this.loggedInUserData.linkedKeyPriorities.length
    },
    showAuthModalLoader () {
      return this.isLogging || this.isRegistering || this.sendingPasswordResetLink || this.updatingPassword
    },
    isPendoEnabled () {
      return window.pendo && this.loggedInUser
    },
    pendoOptions () {
      const user = this.loggedInUser
      const userMeta = user.metadata || {}

      return {
        visitor: {
          id: user.uid,
          email: user.email || '',
          full_name: user.displayName || ''
        },
        account: {
          id: user.uid,
          name: user.displayName || '',
          email: user.email || '',
          creation_date: userMeta.creationTime || '',
          last_logged_in: userMeta.lastSignInTime || ''
        }
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.selectActiveTab()
        this.verifyCode()
      }
    }
  },
  created () {
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    if (process.env.NODE_ENV !== 'development') { axios.defaults.baseURL = process.env.VUE_APP_TEAMWORK_API_URL }

    this.setFirebaseReady(false)
  },
  mounted () {
    // Load user data from firebase and watch for future changes
    auth().onAuthStateChanged(this.fetchUserDetails)

    auth().getRedirectResult().then(result => {
      if (!result.user) return
      this.$analytics(result.additionalUserInfo.isNewUser ? 'sign_up' : 'login', { email: result.user.email })
      this.$router.replace({ query: null })
    }).catch(error => this.setMessage({
      type: 'error',
      message: `${error.message} ${(
        error.code === 'auth/web-storage-unsupported'
          ? 'Please leave private/incognito mode if you are in.' : ''
      )}`,
      timeout: 30 * 1000
    }))
  },
  destroyed () {
    // prevent memory leaks
    this.dataManager.destroy()
  },
  methods: {
    ...mapActions([
      'configAxios',
      'getAccountDetails',
      'setLoggedInUser',
      'setLoggedInUserData',
      'setFirebaseReady',
      'setMessage',
      'hideMessage',
      'toggleSideNav'
    ]),
    initializePendo () {
      window.pendo.initialize(this.pendoOptions)
    },
    refreshPendo () {
      window.pendo.identify(this.pendoOptions)
    },
    selectActiveTab () {
      const path = this.$route.path
      const pos = ['/', '/faqs'].indexOf(path)
      this.selectedTab = pos > -1 ? path : '/x'
    },
    async verifyCode () {
      const q = this.$route.query
      if (q.mode !== 'resetPassword' || !q.oobCode) return

      try {
        await auth().verifyPasswordResetCode(q.oobCode)
        this.$refs.signInModal.openChangePasswordDialog()
      } catch (error) {
        const codeIsExpired = error.code === 'auth/expired-action-code'
        if (codeIsExpired) this.$refs.signInModal.openResetPasswordDialog()
        this.setMessage({ type: 'error', message: (error.message || '').replace('action', '') })
      }
    },
    goToHome () {
      if (this.$route.name !== 'home') this.$router.push({ name: 'home' })
    },
    loginWithGoogle () {
      const provider = new auth.GoogleAuthProvider()
      this._login(provider)
    },
    loginWithMicrosoft () {
      const provider = new auth.OAuthProvider('microsoft.com')
      this._login(provider)
    },
    async loginWithEmailPassword (user) {
      try {
        this.isLogging = true
        await auth().signInWithEmailAndPassword(user.email, user.password)
        this.$analytics('login', { email: user.email })
        this.setMessage({ type: 'success', message: 'Sign in successful' })
      } catch (error) {
        const msg = error.code === 'auth/user-not-found' ? 'We are unable to find an user account with given credentials' : error.message
        this.setMessage({
          type: 'error',
          message: msg
        })
      } finally {
        this.isLogging = false
      }
    },
    async signupWithEmailPassword (user) {
      try {
        this.isRegistering = true
        await auth().createUserWithEmailAndPassword(user.email, user.password)
        const currentUser = auth().currentUser

        currentUser.updateProfile({
          displayName: user.name,
          photoURL: ''
        })

        this.$analytics('sign_up', { email: user.email })
        this.setMessage({ type: 'success', message: 'Registration successful' })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      } finally {
        this.isRegistering = false
      }
    },
    async _login (provider) {
      try {
        const user = auth().currentUser
        await auth().signInWithRedirect(provider)

        // remove anonymous user
        if (user) {
          user.delete()
          db.collection('users').doc(user.uid).delete()
        }
      } catch (error) {
        this.setMessage({ type: 'error', message: error })
      }
    },
    async fetchUserDetails (user) {
      if (user === null) {
        this.setLoggedInUser(null)
        return this.setFirebaseReady()
      }

      // Log in the user
      await this.setLoggedInUser(user)
      await this.fetchUserData()

      if (this.numberOfItems > 0 && this.hasFooter && this.$route.name !== 'templates') {
        this.$router.push({ name: 'home' })
      }

      // track activities with pendo
      if (this.isPendoEnabled) {
        this.isFirebaseReady ? this.refreshPendo() : this.initializePendo()
      }
      // Let the app know that firebase is ready
      this.setFirebaseReady()
    },
    async fetchUserData () {
      return new Promise((resolve) => {
        this.dataManager.syncObject('users', this.loggedInUser.uid, async (error, doc) => {
          if (error) {
            this.setMessage({ type: 'error', message: `Error getting user details: ${error.message}` })
            return resolve()
          }

          let userData = {}
          if (doc.exists) userData = doc.data()

          this.setLoggedInUserData(userData)

          if (userData.twAccessToken && userData.twAPIEndPoint && (!userData.twURL || !userData.twSiteName)) {
            await this.addMissingInfo(userData)
          }
          resolve()
        })
      })
    },
    // initially we were not saving domain and api endpoint URL only
    // now we need domain to provide link to Teamwork task and
    // sitename for multiple TW site support (so they can easily identify based on name)
    // add missing data to existing records
    async addMissingInfo (userData) {
      try {
        await this.configAxios({ token: userData.twAccessToken, isAPIKey: false, apiEndPoint: userData.twAPIEndPoint })
        const { data } = await this.getAccountDetails()
        userData.twURL = data.account.URL
        userData.twSiteName = data.account.name
      } catch (error) {
        if (error.response.status !== 401) return
        this.setMessage({
          type: 'error',
          message: `We are unable to sync with Teamwork site (${userData.twURL}). Please sync again.`
        })
        // remove sync data if unauthorized
        userData.twAccessToken = ''
        userData.twAPIEndPoint = ''
        userData.twURL = ''
      } finally {
        this.setLoggedInUserData(userData)
        this.dataManager.updateLoggedInUserData(userData)
      }

      return userData
    },
    async logout () {
      try {
        await auth().signOut()
        // Sign-out successful
        this.setLoggedInUser(null)
        this.setLoggedInUserData({
          linkedVTOs: [],
          linkedCharts: [],
          linkedLevel10s: [],
          linkedScorecards: [],
          linkedKeyPriorities: []
        })
        this.goToHome()
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error logging off: ${error.message}` })
      }
    },
    async resetPassword (email) {
      try {
        this.sendingPasswordResetLink = true
        await auth().sendPasswordResetEmail(email)
        this.$refs.signInModal.closeDialog()
        this.setMessage({ type: 'success', message: 'Please check your email for further instructions' })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      } finally {
        this.sendingPasswordResetLink = false
      }
    },
    async updatePassword (newPassword) {
      try {
        const code = this.$route.query.oobCode
        if (!code || !newPassword) return

        this.updatingPassword = true
        await auth().confirmPasswordReset(code, newPassword)

        this.$refs.signInModal.closeDialog()
        this.setMessage({ type: 'success', message: 'Password updated successfully.' })

        await this.$nextTick()
        this.$refs.signInModal.openDialog()
        this.$router.replace({ query: null })
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      } finally {
        this.updatingPassword = false
      }
    },
    async createVTO () {
      try {
        this.isCreatingVTO = true
        const { objectId } = await this.dataManager.createObject('vtos')

        this.$router.push({
          name: 'visionandplans',
          params: { vtoId: objectId }
        })

        this.$analytics('created_visionandplan', { id: objectId })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error creating vision & plan: ${error.message}` })
      } finally {
        this.isCreatingVTO = false
      }
    },
    async createLevel10Meeting (template) {
      try {
        this.isCreatingL10Meeting = true
        const { objectId } = await this.dataManager.createObject('level10s', { template })

        this.$router.push({
          name: 'weeklymeetings',
          params: { level10Id: objectId }
        })

        this.$analytics('created_meeting', { id: objectId })
        if (template) this.$analytics('meeting_template_used', { template })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error creating weekly meeting: ${error.message}` })
      } finally {
        this.isCreatingL10Meeting = false
      }
    },
    async createAccountabilityChart () {
      try {
        this.isCreatingAccountabilityChart = true
        const { objectId } = await this.dataManager.createObject('charts')

        this.$router.push({
          name: 'organisational-charts',
          params: { chartId: objectId }
        })

        this.$analytics('created_org_chart', { id: objectId })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error creating org. chart: ${error.message}` })
      } finally {
        this.isCreatingAccountabilityChart = false
      }
    },
    async createScorecard () {
      try {
        this.isCreatingScorecard = true
        const { objectId } = await this.dataManager.createObject('scorecards')

        this.$router.push({
          name: 'kpiscorecards',
          params: { scorecardId: objectId }
        })

        this.$analytics('created_scorecard', { id: objectId })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error creating scorecard: ${error.message}` })
      } finally {
        this.isCreatingScorecard = false
      }
    },
    async createKeyPriorities () {
      try {
        this.isCreatingKeyPriorities = true
        const { objectId } = await this.dataManager.createObject('keypriorities')

        this.$router.push({
          name: 'keypriorities',
          params: { keyPrioritiesId: objectId }
        })

        this.$analytics('created_kptool', { id: objectId })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error creating key priorities: ${error.message}` })
      } finally {
        this.isCreatingKeyPriorities = false
      }
    },
    leaveFeedback () {
      window.open('https://digitalcrew.teamwork.com/p/forms/kkJkpYEuBykDNdq7q725', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-app__toolbar {
  z-index: 6 !important;
}

.eos-app__title {
  overflow: visible;
}

.eos-app__content {
  box-sizing: border-box;
  min-height: calc(100vh - 48px);
  overflow: auto;
}

.eos-app__bg--gray {
  background-color: #f5f7fa;
}

.eos-app__footer {
  width: 100%;
  max-width: 1318px;

  a:not(:hover) {
    text-decoration: none;
  }
}
</style>
