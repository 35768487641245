<template>
  <v-list-item exact :to="{ path: `${itemPath}` }">
    <v-list-item-action>
      <v-icon>{{ prependIcon }}</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>{{ title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'DrawerEOSItemLink',
  props: {
    itemPath: {
      type: String,
      required: true
    },
    prependIcon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
