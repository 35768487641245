<template>
  <v-dialog
    :value="true"
    persistent
    :max-width="maxWidth"
  >
    <v-card>
      <v-card-title class="h6">{{ title }}</v-card-title>
      <v-divider />
      <v-card-text class="pt-3" v-html="message" />
      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn text @click="$emit('cancel')">Cancel</v-btn>
        <v-btn id="btnConfirmDelete" color="error" @click="$emit('confirm')">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppDialogConfirmDelete',
  props: {
    maxWidth: {
      type: [Number, String],
      default: '350px'
    },
    title: {
      type: String,
      default: 'Are you sure?'
    },
    message: {
      type: String,
      default: ''
    }
  },
  async mounted () {
    await this.$nextTick()
    document.getElementById('btnConfirmDelete').focus()
  }
}
</script>
