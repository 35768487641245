<template>
  <v-progress-circular :size="size" :width="width" :color="color" indeterminate />
</template>

<script>
export default {
  name: 'AppLoader',
  props: {
    size: {
      type: [Number, String],
      default: 50
    },
    width: {
      type: [Number, String],
      default: 4
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
