<template>
  <svg aria-hidden="true" class="eos-icon eos-icon__marketing-strategy" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.05 24.4">
    <g>
      <g>
        <path d="M25.44,19.7h-8.1v1.37a2.19,2.19,0,0,0,2.17,2.21h1.88a.56.56,0,0,1,0,1.12H5.66a.56.56,0,0,1,0-1.12H7.54a2.19,2.19,0,0,0,2.17-2.21V19.7H1.61A1.62,1.62,0,0,1,0,18.07V1.63A1.62,1.62,0,0,1,1.61,0H25.45a1.57,1.57,0,0,1,1.13.48,1.65,1.65,0,0,1,.47,1.15V18.07a1.62,1.62,0,0,1-1.61,1.63m-8.38,3.58-.13-.17a3.33,3.33,0,0,1-.69-2V19.7H10.81v1.37a3.33,3.33,0,0,1-.69,2l-.13.17h7.07M26,1.63a.51.51,0,0,0-.51-.51H1.61a.51.51,0,0,0-.51.51V14.28H26V1.63M26,15.4H1.1v2.67a.51.51,0,0,0,.51.51H25.44a.51.51,0,0,0,.51-.51V15.4M13.53,17.6a.61.61,0,1,1,.6-.61.61.61,0,0,1-.6.61M5.81,7.12H8.26a.53.53,0,0,1,.51.54v4a.52.52,0,0,1-.51.53H5.81a.52.52,0,0,1-.51-.53v-4a.53.53,0,0,1,.51-.54m.51,4H7.75v-3H6.32v3m3.86-6.37h2.45a.52.52,0,0,1,.5.54v6.36a.51.51,0,0,1-.5.53H10.18a.52.52,0,0,1-.51-.53V5.31a.53.53,0,0,1,.51-.54m.51,6.37h1.43V5.84H10.69v5.3M14.55,6.8H17a.51.51,0,0,1,.5.53v4.34a.51.51,0,0,1-.5.53H14.55a.52.52,0,0,1-.51-.53V7.33a.52.52,0,0,1,.51-.53m.5,4.34h1.44V7.86H15.05v3.28m3.74-7.52h2.45a.52.52,0,0,1,.51.53v7.52a.52.52,0,0,1-.51.53H18.79a.51.51,0,0,1-.5-.53V4.15a.51.51,0,0,1,.5-.53m.51,7.52h1.44V4.68H19.3v6.46"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconMarketingStrategy'
}
</script>
