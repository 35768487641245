// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

// custom icons
import IconCoreValues from '@/components/shared/customIcons/IconCoreValues'
import IconCoreFocus from '@/components/shared/customIcons/IconCoreFocus'
import IconTarget from '@/components/shared/customIcons/IconTarget'
import IconMarketingStrategy from '@/components/shared/customIcons/IconMarketingStrategy'
import IconPicture from '@/components/shared/customIcons/IconPicture'
import IconPlan from '@/components/shared/customIcons/IconPlan'
import IconRocks from '@/components/shared/customIcons/IconRocks'
import IconIssueList from '@/components/shared/customIcons/IconIssueList'
import IconTeamwork from '@/components/shared/customIcons/IconTeamwork'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi',
    values: {
      'tw-core-values': { component: IconCoreValues },
      'tw-core-focus': { component: IconCoreFocus },
      'tw-target': { component: IconTarget },
      'tw-marketing-strategy': { component: IconMarketingStrategy },
      'tw-picture': { component: IconPicture },
      'tw-plan': { component: IconPlan },
      'tw-rocks': { component: IconRocks },
      'tw-issue-list': { component: IconIssueList },
      'tw-teamwork': { component: IconTeamwork }
    }
  },
  // ref: https://vuetifyjs.com/en/features/theme/#customizing
  theme: {
    themes: {
      light: {
        secondary: '#222',
        error: colors.red
      }
    }
  }
}

export default new Vuetify(opts)
