const mutations = {
  SET_LOGGED_IN_USER (state, user) {
    state.loggedInUser = user
  },
  SET_LOGGED_IN_USER_DATA (state, userData) {
    state.loggedInUserData = { ...state.loggedInUserData, ...userData }
  },
  SET_FIREBASE_READY_STATUS (state, status) {
    state.isFirebaseReady = status
  },
  SET_MESSAGE (state, messageInfo) {
    state.messageType = messageInfo.type
    state.messageText = messageInfo.message
  },
  SET_MESSAGE_STATUS (state, show) {
    state.showMessage = show
  },
  TOGGLE_SIDE_NAV (state) {
    state.isSideNavOpen = !state.isSideNavOpen
  }
}

export default mutations
