<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    :sort-by="sortBy"
    :style="{ width: width }"
    :hide-default-header="!showDefaultHeader"
    :hide-default-footer="!showDefaultFooter"
  >
    <template v-for="(index, slotName) in $scopedSlots" v-slot:[slotName]="data">
      <slot :name="slotName" v-bind="data"></slot>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'AppDataTable',
  props: {
    headers: {
      type: Array,
      default: () => ([])
    },
    items: {
      type: Array,
      default: () => ([])
    },
    width: {
      type: [Number, String],
      default: '100%'
    },
    itemsPerPage: {
      type: Number,
      default: -1
    },
    sortBy: {
      type: String,
      default: ''
    },
    showDefaultHeader: {
      type: Boolean,
      default: false
    },
    showDefaultFooter: {
      type: Boolean,
      default: false
    }
  }
}
</script>
