import linkify from './linkify'

export default function (text, ordered = false) {
  if (!text) return ''
  const listType = ordered ? 'ol' : 'ul'

  text = ('' + text)
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
  text = linkify(text)

  return `<${listType}><li>${('' + text).replace(/\n/g, '<li />')}</${listType}>`
}
