<template>
  <div>
    <v-avatar
      v-if="src"
      :size="size"
      class="ml-2"
      item
    >
      <v-img
        :src="src"
        :alt="name"
      />
    </v-avatar>
    <v-avatar v-else item :size="size" class="ml-2 eos-avatar--bordered">
      <span :class="nameClass">{{ shortName }}</span>
    </v-avatar>
  </div>
</template>

<script>
export default {
  name: 'AppAvatar',
  props: {
    size: {
      type: [Number, String],
      default: '32px'
    },
    src: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    nameClass: {
      type: [String, Array],
      default: 'secondary--text eos-avatar__sort-name'
    }
  },
  computed: {
    shortName () {
      return this.$options.filters.shortName(this.name) || this.name
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-avatar--bordered {
  border:1px solid #777;
}

.eos-avatar__sort-name {
  font-size:10px;
}
</style>
