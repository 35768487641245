<template>
  <svg aria-hidden="true" class="eos-icon eos-icon__picture" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.32 26.92">
    <g>
      <g>
        <path d="M20.84,23.91a1.72,1.72,0,0,1-.79-.2l-5.62-2.94a.52.52,0,0,0-.27-.07.5.5,0,0,0-.27.07L8.27,23.71a1.72,1.72,0,0,1-.79.2,1.69,1.69,0,0,1-1.67-2l1.08-6.25a.57.57,0,0,0-.17-.51L2.17,10.76a1.69,1.69,0,0,1,.94-2.88L9.39,7a.59.59,0,0,0,.44-.32L12.64,1A1.69,1.69,0,0,1,14.16,0,1.66,1.66,0,0,1,15.67,1l2.82,5.68a.57.57,0,0,0,.43.32l6.29.91A1.66,1.66,0,0,1,26.57,9a1.69,1.69,0,0,1-.42,1.73L21.6,15.18a.57.57,0,0,0-.17.51l1.07,6.25a1.68,1.68,0,0,1-1.66,2M14.16,19.6a1.7,1.7,0,0,1,.79.19l5.62,2.95a.64.64,0,0,0,.27.07.54.54,0,0,0,.34-.12.55.55,0,0,0,.23-.57l-1.07-6.24a1.71,1.71,0,0,1,.48-1.49L25.37,10a.58.58,0,0,0,.15-.6.59.59,0,0,0-.47-.4l-6.29-.91a1.69,1.69,0,0,1-1.27-.92L14.68,1.46a.59.59,0,0,0-.52-.33.58.58,0,0,0-.52.33L10.83,7.14a1.7,1.7,0,0,1-1.28.92L3.27,9a.56.56,0,0,0-.47.4.57.57,0,0,0,.14.6l4.55,4.42A1.69,1.69,0,0,1,8,15.88L6.9,22.12a.58.58,0,0,0,.24.57.54.54,0,0,0,.34.12.68.68,0,0,0,.27-.07l5.62-2.95a1.67,1.67,0,0,1,.79-.19"/>
        <path d="M14.16,26.92a.56.56,0,0,1-.56-.55V25a.56.56,0,0,1,1.11,0v1.37a.55.55,0,0,1-.55.55m13.6-9.86-.17,0-1.3-.42a.54.54,0,0,1-.32-.27.52.52,0,0,1,0-.42.55.55,0,0,1,.52-.39l.17,0,1.31.42a.59.59,0,0,1,.32.28.56.56,0,0,1,0,.42.55.55,0,0,1-.53.38m-27.21,0A.54.54,0,0,1,0,16.68a.51.51,0,0,1,0-.42A.59.59,0,0,1,.38,16l1.3-.42.18,0a.55.55,0,0,1,.52.39.49.49,0,0,1,0,.41.55.55,0,0,1-.32.28L.73,17l-.18,0M21.76,2.21a.51.51,0,0,1-.32-.1.61.61,0,0,1-.23-.36.59.59,0,0,1,.1-.41L22.12.23A.56.56,0,0,1,22.57,0a.54.54,0,0,1,.32.11.53.53,0,0,1,.22.36.5.5,0,0,1-.1.41L22.21,2a.55.55,0,0,1-.45.22m-15.21,0A.59.59,0,0,1,6.1,2L5.3.88A.55.55,0,0,1,5.2.47.51.51,0,0,1,5.42.11.55.55,0,0,1,6.2.23L7,1.33a.55.55,0,0,1,.1.41.51.51,0,0,1-.22.36.57.57,0,0,1-.33.11"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconPicture'
}
</script>
